// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file booking/v1/reservations.proto (package booking.v1, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage} from "@bufbuild/protobuf";
import {Message, proto3, Timestamp} from "@bufbuild/protobuf";
import {Date} from "../../google/type/date_pb.js";

/**
 * @generated from enum booking.v1.ReservationState
 */
export enum ReservationState {
  /**
   * @generated from enum value: RESERVATION_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: RESERVATION_STATE_REQUIRES_GUESTS = 1;
   */
  REQUIRES_GUESTS = 1,

  /**
   * @generated from enum value: RESERVATION_STATE_REQUIRES_PAYMENT_METHOD = 2;
   */
  REQUIRES_PAYMENT_METHOD = 2,

  /**
   * @generated from enum value: RESERVATION_STATE_BOOKING = 3;
   */
  BOOKING = 3,

  /**
   * @generated from enum value: RESERVATION_STATE_CONFIRMED = 4;
   */
  CONFIRMED = 4,

  /**
   * @generated from enum value: RESERVATION_STATE_CANCELLED = 5;
   */
  CANCELLED = 5,

  /**
   * @generated from enum value: RESERVATION_STATE_EXPIRED = 6;
   */
  EXPIRED = 6,

  /**
   * @generated from enum value: RESERVATION_STATE_ERROR_SUPPORT_REQUIRED = 7;
   */
  ERROR_SUPPORT_REQUIRED = 7,

  /**
   * @generated from enum value: RESERVATION_STATE_ERROR_USER_SELECTION_REQUIRED = 8;
   */
  ERROR_USER_SELECTION_REQUIRED = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(ReservationState)
proto3.util.setEnumType(ReservationState, "booking.v1.ReservationState", [
  { no: 0, name: "RESERVATION_STATE_UNSPECIFIED" },
  { no: 1, name: "RESERVATION_STATE_REQUIRES_GUESTS" },
  { no: 2, name: "RESERVATION_STATE_REQUIRES_PAYMENT_METHOD" },
  { no: 3, name: "RESERVATION_STATE_BOOKING" },
  { no: 4, name: "RESERVATION_STATE_CONFIRMED" },
  { no: 5, name: "RESERVATION_STATE_CANCELLED" },
  { no: 6, name: "RESERVATION_STATE_EXPIRED" },
  { no: 7, name: "RESERVATION_STATE_ERROR_SUPPORT_REQUIRED" },
  { no: 8, name: "RESERVATION_STATE_ERROR_USER_SELECTION_REQUIRED" },
]);

/**
 * @generated from enum booking.v1.BookingWorkflowState
 */
export enum BookingWorkflowState {
  /**
   * @generated from enum value: BOOKING_WORKFLOW_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: BOOKING_WORKFLOW_STATE_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: BOOKING_WORKFLOW_STATE_CHECK_FLIGHT_AVAILABILITY = 2;
   */
  CHECK_FLIGHT_AVAILABILITY = 2,

  /**
   * @generated from enum value: BOOKING_WORKFLOW_STATE_CHECK_HOTEL_ROOM_PRICES = 3;
   */
  CHECK_HOTEL_ROOM_PRICES = 3,

  /**
   * @generated from enum value: BOOKING_WORKFLOW_STATE_RESERVE_HOTEL_ROOM = 4;
   */
  RESERVE_HOTEL_ROOM = 4,

  /**
   * @generated from enum value: BOOKING_WORKFLOW_STATE_CAPTURE_PAYMENT = 6;
   */
  CAPTURE_PAYMENT = 6,

  /**
   * @generated from enum value: BOOKING_WORKFLOW_STATE_CONFIRM_FLIGHTS_WITH_AIRLINE = 5;
   */
  CONFIRM_FLIGHTS_WITH_AIRLINE = 5,

  /**
   * @generated from enum value: BOOKING_WORKFLOW_STATE_CONFIRM_HOTEL_RESERVATION = 7;
   */
  CONFIRM_HOTEL_RESERVATION = 7,

  /**
   * @generated from enum value: BOOKING_WORKFLOW_STATE_SEND_CONFIRMATION = 8;
   */
  SEND_CONFIRMATION = 8,

  /**
   * @generated from enum value: BOOKING_WORKFLOW_STATE_CONFIRMED = 9;
   */
  CONFIRMED = 9,
}
// Retrieve enum metadata with: proto3.getEnumType(BookingWorkflowState)
proto3.util.setEnumType(BookingWorkflowState, "booking.v1.BookingWorkflowState", [
  { no: 0, name: "BOOKING_WORKFLOW_STATE_UNSPECIFIED" },
  { no: 1, name: "BOOKING_WORKFLOW_STATE_PENDING" },
  { no: 2, name: "BOOKING_WORKFLOW_STATE_CHECK_FLIGHT_AVAILABILITY" },
  { no: 3, name: "BOOKING_WORKFLOW_STATE_CHECK_HOTEL_ROOM_PRICES" },
  { no: 4, name: "BOOKING_WORKFLOW_STATE_RESERVE_HOTEL_ROOM" },
  { no: 6, name: "BOOKING_WORKFLOW_STATE_CAPTURE_PAYMENT" },
  { no: 5, name: "BOOKING_WORKFLOW_STATE_CONFIRM_FLIGHTS_WITH_AIRLINE" },
  { no: 7, name: "BOOKING_WORKFLOW_STATE_CONFIRM_HOTEL_RESERVATION" },
  { no: 8, name: "BOOKING_WORKFLOW_STATE_SEND_CONFIRMATION" },
  { no: 9, name: "BOOKING_WORKFLOW_STATE_CONFIRMED" },
]);

/**
 * @generated from enum booking.v1.TransitionState
 */
export enum TransitionState {
  /**
   * @generated from enum value: TRANSITION_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TRANSITION_STATE_STARTED = 1;
   */
  STARTED = 1,

  /**
   * @generated from enum value: TRANSITION_STATE_FAILED = 2;
   */
  FAILED = 2,

  /**
   * @generated from enum value: TRANSITION_STATE_COMPLETED = 3;
   */
  COMPLETED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(TransitionState)
proto3.util.setEnumType(TransitionState, "booking.v1.TransitionState", [
  { no: 0, name: "TRANSITION_STATE_UNSPECIFIED" },
  { no: 1, name: "TRANSITION_STATE_STARTED" },
  { no: 2, name: "TRANSITION_STATE_FAILED" },
  { no: 3, name: "TRANSITION_STATE_COMPLETED" },
]);

/**
 * @generated from message booking.v1.ListReservationsFilter
 */
export class ListReservationsFilter extends Message<ListReservationsFilter> {
  /**
   * @generated from field: string hotel_property_id_eq = 1;
   */
  hotelPropertyIdEq = "";

  constructor(data?: PartialMessage<ListReservationsFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "booking.v1.ListReservationsFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "hotel_property_id_eq", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListReservationsFilter {
    return new ListReservationsFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListReservationsFilter {
    return new ListReservationsFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListReservationsFilter {
    return new ListReservationsFilter().fromJsonString(jsonString, options);
  }

  static equals(a: ListReservationsFilter | PlainMessage<ListReservationsFilter> | undefined, b: ListReservationsFilter | PlainMessage<ListReservationsFilter> | undefined): boolean {
    return proto3.util.equals(ListReservationsFilter, a, b);
  }
}

/**
 * @generated from message booking.v1.ListReservationsRequest
 */
export class ListReservationsRequest extends Message<ListReservationsRequest> {
  /**
   * @generated from field: booking.v1.ListReservationsFilter filter = 1;
   */
  filter?: ListReservationsFilter;

  /**
   * @generated from field: optional int32 page_size = 2;
   */
  pageSize?: number;

  /**
   * @generated from field: optional string page_token = 3;
   */
  pageToken?: string;

  constructor(data?: PartialMessage<ListReservationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "booking.v1.ListReservationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filter", kind: "message", T: ListReservationsFilter },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListReservationsRequest {
    return new ListReservationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListReservationsRequest {
    return new ListReservationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListReservationsRequest {
    return new ListReservationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListReservationsRequest | PlainMessage<ListReservationsRequest> | undefined, b: ListReservationsRequest | PlainMessage<ListReservationsRequest> | undefined): boolean {
    return proto3.util.equals(ListReservationsRequest, a, b);
  }
}

/**
 * @generated from message booking.v1.ListReservationsResponse
 */
export class ListReservationsResponse extends Message<ListReservationsResponse> {
  /**
   * @generated from field: repeated booking.v1.Reservation reservations = 1;
   */
  reservations: Reservation[] = [];

  /**
   * @generated from field: optional string next_page_token = 2;
   */
  nextPageToken?: string;

  constructor(data?: PartialMessage<ListReservationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "booking.v1.ListReservationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reservations", kind: "message", T: Reservation, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListReservationsResponse {
    return new ListReservationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListReservationsResponse {
    return new ListReservationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListReservationsResponse {
    return new ListReservationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListReservationsResponse | PlainMessage<ListReservationsResponse> | undefined, b: ListReservationsResponse | PlainMessage<ListReservationsResponse> | undefined): boolean {
    return proto3.util.equals(ListReservationsResponse, a, b);
  }
}

/**
 * @generated from message booking.v1.Reservation
 */
export class Reservation extends Message<Reservation> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: google.type.Date starts_at = 2;
   */
  startsAt?: Date;

  /**
   * @generated from field: google.type.Date finishes_at = 3;
   */
  finishesAt?: Date;

  /**
   * @generated from field: booking.v1.ReservationState state = 4;
   */
  state = ReservationState.UNSPECIFIED;

  /**
   * @generated from field: string reference = 5;
   */
  reference = "";

  /**
   * @generated from field: int32 adults = 6;
   */
  adults = 0;

  /**
   * @generated from field: repeated int32 child_ages = 7;
   */
  childAges: number[] = [];

  /**
   * @generated from field: optional int32 inclusive_price = 8;
   */
  inclusivePrice?: number;

  /**
   * @generated from field: optional int32 exclusive_price = 9;
   */
  exclusivePrice?: number;

  /**
   * @generated from field: optional int32 taxes_and_fees = 10;
   */
  taxesAndFees?: number;

  /**
   * @generated from field: optional int32 commission = 11;
   */
  commission?: number;

  /**
   * @generated from field: optional int32 expedia_itinerary_id = 12;
   */
  expediaItineraryId?: number;

  /**
   * @generated from field: optional string stripe_payment_intent_id = 13;
   */
  stripePaymentIntentId?: string;

  /**
   * @generated from field: google.protobuf.Timestamp confirmed_at = 14;
   */
  confirmedAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp paid_at = 15;
   */
  paidAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp cancelled_at = 16;
   */
  cancelledAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp expired_at = 17;
   */
  expiredAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 100;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 101;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: bool test_mode = 18;
   */
  testMode = false;

  constructor(data?: PartialMessage<Reservation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "booking.v1.Reservation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "starts_at", kind: "message", T: Date },
    { no: 3, name: "finishes_at", kind: "message", T: Date },
    { no: 4, name: "state", kind: "enum", T: proto3.getEnumType(ReservationState) },
    { no: 5, name: "reference", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "adults", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "child_ages", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 8, name: "inclusive_price", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "exclusive_price", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 10, name: "taxes_and_fees", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "commission", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 12, name: "expedia_itinerary_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 13, name: "stripe_payment_intent_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "confirmed_at", kind: "message", T: Timestamp },
    { no: 15, name: "paid_at", kind: "message", T: Timestamp },
    { no: 16, name: "cancelled_at", kind: "message", T: Timestamp },
    { no: 17, name: "expired_at", kind: "message", T: Timestamp },
    { no: 100, name: "created_at", kind: "message", T: Timestamp },
    { no: 101, name: "updated_at", kind: "message", T: Timestamp },
    { no: 18, name: "test_mode", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Reservation {
    return new Reservation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Reservation {
    return new Reservation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Reservation {
    return new Reservation().fromJsonString(jsonString, options);
  }

  static equals(a: Reservation | PlainMessage<Reservation> | undefined, b: Reservation | PlainMessage<Reservation> | undefined): boolean {
    return proto3.util.equals(Reservation, a, b);
  }
}

/**
 * @generated from message booking.v1.BookingWorkflowEvent
 */
export class BookingWorkflowEvent extends Message<BookingWorkflowEvent> {
  /**
   * @generated from field: booking.v1.BookingWorkflowState state = 1;
   */
  state = BookingWorkflowState.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 2;
   */
  timestamp?: Timestamp;

  /**
   * @generated from field: booking.v1.TransitionState transition = 3;
   */
  transition = TransitionState.UNSPECIFIED;

  /**
   * @generated from oneof booking.v1.BookingWorkflowEvent.data
   */
  data: {
    /**
     * @generated from field: booking.v1.BookingWorkflowPendingData pending_data = 4;
     */
    value: BookingWorkflowPendingData;
    case: "pendingData";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<BookingWorkflowEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "booking.v1.BookingWorkflowEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "enum", T: proto3.getEnumType(BookingWorkflowState) },
    { no: 2, name: "timestamp", kind: "message", T: Timestamp },
    { no: 3, name: "transition", kind: "enum", T: proto3.getEnumType(TransitionState) },
    { no: 4, name: "pending_data", kind: "message", T: BookingWorkflowPendingData, oneof: "data" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookingWorkflowEvent {
    return new BookingWorkflowEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookingWorkflowEvent {
    return new BookingWorkflowEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookingWorkflowEvent {
    return new BookingWorkflowEvent().fromJsonString(jsonString, options);
  }

  static equals(a: BookingWorkflowEvent | PlainMessage<BookingWorkflowEvent> | undefined, b: BookingWorkflowEvent | PlainMessage<BookingWorkflowEvent> | undefined): boolean {
    return proto3.util.equals(BookingWorkflowEvent, a, b);
  }
}

/**
 * @generated from message booking.v1.BookingWorkflowPendingData
 */
export class BookingWorkflowPendingData extends Message<BookingWorkflowPendingData> {
  /**
   * @generated from field: repeated booking.v1.BookingWorkflowState possible_states = 1;
   */
  possibleStates: BookingWorkflowState[] = [];

  constructor(data?: PartialMessage<BookingWorkflowPendingData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "booking.v1.BookingWorkflowPendingData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "possible_states", kind: "enum", T: proto3.getEnumType(BookingWorkflowState), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookingWorkflowPendingData {
    return new BookingWorkflowPendingData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookingWorkflowPendingData {
    return new BookingWorkflowPendingData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookingWorkflowPendingData {
    return new BookingWorkflowPendingData().fromJsonString(jsonString, options);
  }

  static equals(a: BookingWorkflowPendingData | PlainMessage<BookingWorkflowPendingData> | undefined, b: BookingWorkflowPendingData | PlainMessage<BookingWorkflowPendingData> | undefined): boolean {
    return proto3.util.equals(BookingWorkflowPendingData, a, b);
  }
}

