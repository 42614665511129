<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { slide } from 'svelte/transition'

  export let title: string
  export let issues: string[] = []
  export let message: string = null
  export let type: 'error' | 'warning' | 'info' = 'error'
  export let inline = false

  $: isError = type === 'error'
  $: isWarning = type === 'warning'
  $: isInfo = type === 'info'

  const dispatch = createEventDispatcher()

  function handleDismissAlert() {
    dispatch('dismiss')
  }
</script>

<div
  class:error={isError}
  class:warning={isWarning}
  class:info={isInfo}
  class="p-4 w-full lg:rounded-md border relative"
  data-testid="alert"
  transition:slide|local
>
  <div class="flex mr-8">
    <div class="flex-shrink-0">
      {#if isError}
        <!-- Heroicon name: solid/x-circle -->
        <svg
          class="h-5 w-5 text-red-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"
          />
        </svg>
      {:else if isWarning}
        <!-- Heroicon name: mini/exclamation-triangle -->
        <svg
          class="h-5 w-5 text-yellow-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M8.485 3.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 3.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z"
            clip-rule="evenodd"
          />
        </svg>
      {:else if isInfo}
        <svg
          class="h-5 w-5 text-green-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
            clip-rule="evenodd"
          />
        </svg>
      {/if}
    </div>
    <div class="ml-3 relative">
      <h3 class="text-sm font-medium title">{title}</h3>
      <div class="text-sm message">
        {#if issues.length}
          <ul class="list-disc pl-5 space-y-1 mt-2">
            {#each issues as issue}
              <li>{issue}</li>
            {/each}
          </ul>
        {:else if message}
          <p class="mt-2">
            {message}
          </p>
        {/if}
      </div>
    </div>
  </div>

  {#if !inline}
    <button
      aria-label="close"
      on:click={handleDismissAlert}
      class="flex absolute top-5 right-4 items-center justify-center -mt-2 w-6 h-6 text-black bg-white rounded-full appearance-none opacity-100 shadow-sm focus:outline-none hover:text-black"
    >
      <svg
        class="w-4 h-4"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width={2}
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  {/if}
</div>

<style lang="postcss">
  .info {
    @apply bg-emerald-50 border-emerald-200;

    h3.title {
      @apply text-emerald-800;
    }

    .message {
      @apply text-emerald-700;
    }
  }
  .warning {
    @apply bg-yellow-50 border-yellow-200;
    h3.title {
      @apply text-yellow-800;
    }

    .message {
      @apply text-yellow-700;
    }
  }
  .error {
    @apply bg-red-50 border-red-200;
    h3.title {
      @apply text-red-800;
    }

    .message {
      @apply text-red-700;
    }
  }
</style>
