// @generated by protoc-gen-connect-web v0.2.1 with parameter "target=ts"
// @generated from file booking/v1/service.proto (package booking.v1, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {InsertHotelReservationRequest, InsertHotelReservationResponse} from "./hotel_reservation_pb.js";
import {Empty, MethodIdempotency, MethodKind} from "@bufbuild/protobuf";
import {AddGuestRequest, AddGuestResponse, CancelReservationRequest, CancelReservationResponse, GetBookingStatusRequest, GetBookingStatusResponse, GetRefundPolicyRequest, GetRefundPolicyResponse, RemoveGuestRequest, SendConfirmationEmailRequest, SendConfirmationEmailResponse} from "./service_pb.js";
import {ListReservationsRequest, ListReservationsResponse} from "./reservations_pb.js";

/**
 * @generated from service booking.v1.BookingService
 */
export const BookingService = {
  typeName: "booking.v1.BookingService",
  methods: {
    /**
     * @generated from rpc booking.v1.BookingService.InsertHotelReservation
     */
    insertHotelReservation: {
      name: "InsertHotelReservation",
      I: InsertHotelReservationRequest,
      O: InsertHotelReservationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc booking.v1.BookingService.GetBookingStatus
     */
    getBookingStatus: {
      name: "GetBookingStatus",
      I: GetBookingStatusRequest,
      O: GetBookingStatusResponse,
      kind: MethodKind.ServerStreaming,
    idempotency: MethodIdempotency.Idempotent,
    },
    /**
     * @generated from rpc booking.v1.BookingService.AddGuest
     */
    addGuest: {
      name: "AddGuest",
      I: AddGuestRequest,
      O: AddGuestResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc booking.v1.BookingService.RemoveGuest
     */
    removeGuest: {
      name: "RemoveGuest",
      I: RemoveGuestRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc booking.v1.BookingService.ListReservations
     */
    listReservations: {
      name: "ListReservations",
      I: ListReservationsRequest,
      O: ListReservationsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc booking.v1.BookingService.GetRefundPolicy
     */
    getRefundPolicy: {
      name: "GetRefundPolicy",
      I: GetRefundPolicyRequest,
      O: GetRefundPolicyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc booking.v1.BookingService.CancelReservation
     */
    cancelReservation: {
      name: "CancelReservation",
      I: CancelReservationRequest,
      O: CancelReservationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc booking.v1.BookingService.SendConfirmationEmail
     */
    sendConfirmationEmail: {
      name: "SendConfirmationEmail",
      I: SendConfirmationEmailRequest,
      O: SendConfirmationEmailResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

