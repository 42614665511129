// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file booking/v1/service.proto (package booking.v1, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage} from "@bufbuild/protobuf";
import {Message, proto3} from "@bufbuild/protobuf";
import {BookingWorkflowEvent, Reservation} from "./reservations_pb.js";
import {Rate_CancellationPenalty} from "../../rates/v1/rate_pb.js";

/**
 * @generated from message booking.v1.GetBookingStatusRequest
 */
export class GetBookingStatusRequest extends Message<GetBookingStatusRequest> {
  /**
   * @generated from field: string reservation_id = 1;
   */
  reservationId = "";

  constructor(data?: PartialMessage<GetBookingStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "booking.v1.GetBookingStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reservation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetBookingStatusRequest {
    return new GetBookingStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetBookingStatusRequest {
    return new GetBookingStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetBookingStatusRequest {
    return new GetBookingStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetBookingStatusRequest | PlainMessage<GetBookingStatusRequest> | undefined, b: GetBookingStatusRequest | PlainMessage<GetBookingStatusRequest> | undefined): boolean {
    return proto3.util.equals(GetBookingStatusRequest, a, b);
  }
}

/**
 * @generated from message booking.v1.GetBookingStatusResponse
 */
export class GetBookingStatusResponse extends Message<GetBookingStatusResponse> {
  /**
   * @generated from field: booking.v1.BookingWorkflowEvent event = 1;
   */
  event?: BookingWorkflowEvent;

  constructor(data?: PartialMessage<GetBookingStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "booking.v1.GetBookingStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "event", kind: "message", T: BookingWorkflowEvent },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetBookingStatusResponse {
    return new GetBookingStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetBookingStatusResponse {
    return new GetBookingStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetBookingStatusResponse {
    return new GetBookingStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetBookingStatusResponse | PlainMessage<GetBookingStatusResponse> | undefined, b: GetBookingStatusResponse | PlainMessage<GetBookingStatusResponse> | undefined): boolean {
    return proto3.util.equals(GetBookingStatusResponse, a, b);
  }
}

/**
 * @generated from message booking.v1.AddGuestRequest
 */
export class AddGuestRequest extends Message<AddGuestRequest> {
  /**
   * @generated from field: string booking_id = 1;
   */
  bookingId = "";

  /**
   * @generated from field: string guest_id = 2;
   */
  guestId = "";

  constructor(data?: PartialMessage<AddGuestRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "booking.v1.AddGuestRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "booking_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "guest_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddGuestRequest {
    return new AddGuestRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddGuestRequest {
    return new AddGuestRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddGuestRequest {
    return new AddGuestRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddGuestRequest | PlainMessage<AddGuestRequest> | undefined, b: AddGuestRequest | PlainMessage<AddGuestRequest> | undefined): boolean {
    return proto3.util.equals(AddGuestRequest, a, b);
  }
}

/**
 * @generated from message booking.v1.AddGuestResponse
 */
export class AddGuestResponse extends Message<AddGuestResponse> {
  constructor(data?: PartialMessage<AddGuestResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "booking.v1.AddGuestResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddGuestResponse {
    return new AddGuestResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddGuestResponse {
    return new AddGuestResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddGuestResponse {
    return new AddGuestResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddGuestResponse | PlainMessage<AddGuestResponse> | undefined, b: AddGuestResponse | PlainMessage<AddGuestResponse> | undefined): boolean {
    return proto3.util.equals(AddGuestResponse, a, b);
  }
}

/**
 * @generated from message booking.v1.RemoveGuestRequest
 */
export class RemoveGuestRequest extends Message<RemoveGuestRequest> {
  /**
   * @generated from field: string workflow_run_id = 1;
   */
  workflowRunId = "";

  /**
   * @generated from field: string guest_id = 2;
   */
  guestId = "";

  constructor(data?: PartialMessage<RemoveGuestRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "booking.v1.RemoveGuestRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workflow_run_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "guest_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveGuestRequest {
    return new RemoveGuestRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveGuestRequest {
    return new RemoveGuestRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveGuestRequest {
    return new RemoveGuestRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveGuestRequest | PlainMessage<RemoveGuestRequest> | undefined, b: RemoveGuestRequest | PlainMessage<RemoveGuestRequest> | undefined): boolean {
    return proto3.util.equals(RemoveGuestRequest, a, b);
  }
}

/**
 * @generated from message booking.v1.GetRefundPolicyRequest
 */
export class GetRefundPolicyRequest extends Message<GetRefundPolicyRequest> {
  /**
   * @generated from field: string reservation_id = 1;
   */
  reservationId = "";

  constructor(data?: PartialMessage<GetRefundPolicyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "booking.v1.GetRefundPolicyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reservation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRefundPolicyRequest {
    return new GetRefundPolicyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRefundPolicyRequest {
    return new GetRefundPolicyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRefundPolicyRequest {
    return new GetRefundPolicyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRefundPolicyRequest | PlainMessage<GetRefundPolicyRequest> | undefined, b: GetRefundPolicyRequest | PlainMessage<GetRefundPolicyRequest> | undefined): boolean {
    return proto3.util.equals(GetRefundPolicyRequest, a, b);
  }
}

/**
 * @generated from message booking.v1.GetRefundPolicyResponse
 */
export class GetRefundPolicyResponse extends Message<GetRefundPolicyResponse> {
  /**
   * @generated from field: rates.v1.Rate.CancellationPenalty cancel_penalties = 1;
   */
  cancelPenalties?: Rate_CancellationPenalty;

  /**
   * @generated from field: int32 cancel_refund_amount = 2;
   */
  cancelRefundAmount = 0;

  /**
   * @generated from field: int32 total_charge_amount = 5;
   */
  totalChargeAmount = 0;

  /**
   * @generated from field: string cancel_refund_currency = 3;
   */
  cancelRefundCurrency = "";

  /**
   * @generated from field: string payment_method_last_four = 4;
   */
  paymentMethodLastFour = "";

  constructor(data?: PartialMessage<GetRefundPolicyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "booking.v1.GetRefundPolicyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cancel_penalties", kind: "message", T: Rate_CancellationPenalty },
    { no: 2, name: "cancel_refund_amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "total_charge_amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "cancel_refund_currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "payment_method_last_four", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRefundPolicyResponse {
    return new GetRefundPolicyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRefundPolicyResponse {
    return new GetRefundPolicyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRefundPolicyResponse {
    return new GetRefundPolicyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetRefundPolicyResponse | PlainMessage<GetRefundPolicyResponse> | undefined, b: GetRefundPolicyResponse | PlainMessage<GetRefundPolicyResponse> | undefined): boolean {
    return proto3.util.equals(GetRefundPolicyResponse, a, b);
  }
}

/**
 * @generated from message booking.v1.CancelReservationRequest
 */
export class CancelReservationRequest extends Message<CancelReservationRequest> {
  /**
   * @generated from field: string reservation_id = 1;
   */
  reservationId = "";

  constructor(data?: PartialMessage<CancelReservationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "booking.v1.CancelReservationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reservation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelReservationRequest {
    return new CancelReservationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelReservationRequest {
    return new CancelReservationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelReservationRequest {
    return new CancelReservationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CancelReservationRequest | PlainMessage<CancelReservationRequest> | undefined, b: CancelReservationRequest | PlainMessage<CancelReservationRequest> | undefined): boolean {
    return proto3.util.equals(CancelReservationRequest, a, b);
  }
}

/**
 * @generated from message booking.v1.CancelReservationResponse
 */
export class CancelReservationResponse extends Message<CancelReservationResponse> {
  /**
   * @generated from field: booking.v1.Reservation reservation = 1;
   */
  reservation?: Reservation;

  constructor(data?: PartialMessage<CancelReservationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "booking.v1.CancelReservationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reservation", kind: "message", T: Reservation },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelReservationResponse {
    return new CancelReservationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelReservationResponse {
    return new CancelReservationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelReservationResponse {
    return new CancelReservationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CancelReservationResponse | PlainMessage<CancelReservationResponse> | undefined, b: CancelReservationResponse | PlainMessage<CancelReservationResponse> | undefined): boolean {
    return proto3.util.equals(CancelReservationResponse, a, b);
  }
}

/**
 * @generated from message booking.v1.SendConfirmationEmailRequest
 */
export class SendConfirmationEmailRequest extends Message<SendConfirmationEmailRequest> {
  /**
   * @generated from field: string reservation_id = 1;
   */
  reservationId = "";

  /**
   * @generated from field: repeated string additional_emails = 2;
   */
  additionalEmails: string[] = [];

  constructor(data?: PartialMessage<SendConfirmationEmailRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "booking.v1.SendConfirmationEmailRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reservation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "additional_emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendConfirmationEmailRequest {
    return new SendConfirmationEmailRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendConfirmationEmailRequest {
    return new SendConfirmationEmailRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendConfirmationEmailRequest {
    return new SendConfirmationEmailRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SendConfirmationEmailRequest | PlainMessage<SendConfirmationEmailRequest> | undefined, b: SendConfirmationEmailRequest | PlainMessage<SendConfirmationEmailRequest> | undefined): boolean {
    return proto3.util.equals(SendConfirmationEmailRequest, a, b);
  }
}

/**
 * @generated from message booking.v1.SendConfirmationEmailResponse
 */
export class SendConfirmationEmailResponse extends Message<SendConfirmationEmailResponse> {
  /**
   * @generated from field: repeated string notification_ids = 1;
   */
  notificationIds: string[] = [];

  constructor(data?: PartialMessage<SendConfirmationEmailResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "booking.v1.SendConfirmationEmailResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "notification_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendConfirmationEmailResponse {
    return new SendConfirmationEmailResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendConfirmationEmailResponse {
    return new SendConfirmationEmailResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendConfirmationEmailResponse {
    return new SendConfirmationEmailResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SendConfirmationEmailResponse | PlainMessage<SendConfirmationEmailResponse> | undefined, b: SendConfirmationEmailResponse | PlainMessage<SendConfirmationEmailResponse> | undefined): boolean {
    return proto3.util.equals(SendConfirmationEmailResponse, a, b);
  }
}

