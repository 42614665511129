import { browser } from '$app/environment'
import { getIdTokenWithExpiresAt } from '$lib/authentication/helpers'
import {
  createConnectTransport,
  createPromiseClient,
  type Interceptor,
} from '@bufbuild/connect-web'
import type { ServiceType } from '@bufbuild/protobuf'

function getApiHost(): string {
  let apiURL = process.env.AIRHEART_API_HOST

  if (apiURL != null) {
    const url = new URL(apiURL)
    if (url.pathname.length > 0) {
      url.pathname = ''
    }
    apiURL = url.toString()

    return apiURL
  } else if (import.meta.env.PROD) {
    // console.log(`AIRHEART_API_HOST is not set, using rpc-production.services.airheart.app`)
    return 'https://api.airheart.com'
  }

  return 'https://preview-api-test-mode.airheart.dev'
  // return 'https://rpc-production.services.airheart.app'
}

export function newTransport() {
  const hasDebugParam = browser ? new URLSearchParams(window.location.search).has('debug') : false
  return createConnectTransport({
    baseUrl: getApiHost(),

    // By default, connect-web clients use the JSON format.
    // Set this option to true to use the binary format.
    useBinaryFormat: import.meta.env.PROD && !hasDebugParam,

    // Controls what the fetch client will do with credentials, such as
    // Cookies. The default value is "same-origin", which will not
    // transmit Cookies in cross-origin requests.
    credentials: 'include',

    // Interceptors apply to all calls running through this transport.
    interceptors: [authenticate],
  })
}

// newClient creates a new client for the given service.
export function newClient<T extends ServiceType>(svc: T) {
  const transport = newTransport()
  return createPromiseClient(svc, transport)
}

const authenticate: Interceptor = (next) => async (req) => {
  const [token, expiresAt] = await getIdTokenWithExpiresAt()

  if (token) {
    req.header.set('Authorization', `Bearer ${token}`)
  }
  if (import.meta.env.DEV) console.debug(`RPC ${req.url} with auth: ${token != null}`)

  return await next(req)
}
