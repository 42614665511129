// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file rates/v1/price_check.proto (package rates.v1, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage} from "@bufbuild/protobuf";
import {Message, proto3} from "@bufbuild/protobuf";
import {Rate} from "./rate_pb.js";

/**
 * @generated from enum rates.v1.Availability
 */
export enum Availability {
  /**
   * @generated from enum value: AVAILABILITY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: AVAILABILITY_AVAILABLE = 1;
   */
  AVAILABLE = 1,

  /**
   * @generated from enum value: AVAILABILITY_PRICE_CHANGED = 2;
   */
  PRICE_CHANGED = 2,

  /**
   * @generated from enum value: AVAILABILITY_SOLD_OUT = 3;
   */
  SOLD_OUT = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Availability)
proto3.util.setEnumType(Availability, "rates.v1.Availability", [
  { no: 0, name: "AVAILABILITY_UNSPECIFIED" },
  { no: 1, name: "AVAILABILITY_AVAILABLE" },
  { no: 2, name: "AVAILABILITY_PRICE_CHANGED" },
  { no: 3, name: "AVAILABILITY_SOLD_OUT" },
]);

/**
 * @generated from message rates.v1.PriceCheckRequest
 */
export class PriceCheckRequest extends Message<PriceCheckRequest> {
  /**
   * @generated from field: string hotel_id = 1;
   */
  hotelId = "";

  /**
   * @generated from field: string room_type_id = 2;
   */
  roomTypeId = "";

  /**
   * @generated from field: int32 rate_id = 3;
   */
  rateId = 0;

  /**
   * @generated from field: string token = 4;
   */
  token = "";

  constructor(data?: PartialMessage<PriceCheckRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "rates.v1.PriceCheckRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "hotel_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "room_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "rate_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PriceCheckRequest {
    return new PriceCheckRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PriceCheckRequest {
    return new PriceCheckRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PriceCheckRequest {
    return new PriceCheckRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PriceCheckRequest | PlainMessage<PriceCheckRequest> | undefined, b: PriceCheckRequest | PlainMessage<PriceCheckRequest> | undefined): boolean {
    return proto3.util.equals(PriceCheckRequest, a, b);
  }
}

/**
 * @generated from message rates.v1.PriceCheckResponse
 */
export class PriceCheckResponse extends Message<PriceCheckResponse> {
  /**
   * @generated from field: rates.v1.Availability status = 1;
   */
  status = Availability.UNSPECIFIED;

  /**
   * @generated from field: repeated rates.v1.Rate rates = 2;
   */
  rates: Rate[] = [];

  /**
   * @generated from field: string book_token = 3;
   */
  bookToken = "";

  constructor(data?: PartialMessage<PriceCheckResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "rates.v1.PriceCheckResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(Availability) },
    { no: 2, name: "rates", kind: "message", T: Rate, repeated: true },
    { no: 3, name: "book_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PriceCheckResponse {
    return new PriceCheckResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PriceCheckResponse {
    return new PriceCheckResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PriceCheckResponse {
    return new PriceCheckResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PriceCheckResponse | PlainMessage<PriceCheckResponse> | undefined, b: PriceCheckResponse | PlainMessage<PriceCheckResponse> | undefined): boolean {
    return proto3.util.equals(PriceCheckResponse, a, b);
  }
}

/**
 * @generated from message rates.v1.PriceChangeContext
 */
export class PriceChangeContext extends Message<PriceChangeContext> {
  /**
   * @generated from field: rates.v1.PriceChangeContext.Charges before = 1;
   */
  before?: PriceChangeContext_Charges;

  /**
   * @generated from field: rates.v1.PriceChangeContext.Charges after = 2;
   */
  after?: PriceChangeContext_Charges;

  constructor(data?: PartialMessage<PriceChangeContext>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "rates.v1.PriceChangeContext";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "before", kind: "message", T: PriceChangeContext_Charges },
    { no: 2, name: "after", kind: "message", T: PriceChangeContext_Charges },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PriceChangeContext {
    return new PriceChangeContext().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PriceChangeContext {
    return new PriceChangeContext().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PriceChangeContext {
    return new PriceChangeContext().fromJsonString(jsonString, options);
  }

  static equals(a: PriceChangeContext | PlainMessage<PriceChangeContext> | undefined, b: PriceChangeContext | PlainMessage<PriceChangeContext> | undefined): boolean {
    return proto3.util.equals(PriceChangeContext, a, b);
  }
}

/**
 * @generated from message rates.v1.PriceChangeContext.Charges
 */
export class PriceChangeContext_Charges extends Message<PriceChangeContext_Charges> {
  /**
   * @generated from field: int32 inclusive_price = 1;
   */
  inclusivePrice = 0;

  /**
   * @generated from field: int32 exclusive_price = 2;
   */
  exclusivePrice = 0;

  /**
   * @generated from field: int32 property_fees = 3;
   */
  propertyFees = 0;

  constructor(data?: PartialMessage<PriceChangeContext_Charges>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "rates.v1.PriceChangeContext.Charges";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "inclusive_price", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "exclusive_price", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "property_fees", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PriceChangeContext_Charges {
    return new PriceChangeContext_Charges().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PriceChangeContext_Charges {
    return new PriceChangeContext_Charges().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PriceChangeContext_Charges {
    return new PriceChangeContext_Charges().fromJsonString(jsonString, options);
  }

  static equals(a: PriceChangeContext_Charges | PlainMessage<PriceChangeContext_Charges> | undefined, b: PriceChangeContext_Charges | PlainMessage<PriceChangeContext_Charges> | undefined): boolean {
    return proto3.util.equals(PriceChangeContext_Charges, a, b);
  }
}

