import type { HotelAttribute } from '$lib/graphql/types'
import type { AmenityFieldsFragment } from '$lib/queries/fragments/generated/AmenitiesFragment'
import type { HotelPropertyFieldsFragment } from '$lib/queries/fragments/generated/HotelPropertyFields'
import { pluralize } from '../text-helpers'
import { petsAllowed, petsNotAllowed } from './callouts'

export const petAttributes = [
  {
    id: '1073742807',
    name: 'Pets allowed (no fees)',
    displayName: 'Pets allowed (no fees)',
    isShown: true,
  },
  {
    id: '1073744793',
    name: 'Only cats are allowed',
    displayName: 'Only Cats are allowed',
    isShown: true,
  },
  {
    id: '1073744794',
    name: 'Pets cannot be left unattended',
    displayName: 'Pets cannot be left unattended',
    isShown: true,
  },
  { id: '2050', name: 'Pets not allowed', displayName: 'Pets not allowed', isShown: false },
  {
    id: '2644',
    name: 'Pet maximum weight in kg is',
    displayName: 'Pet maximum weight in kg is',
    isShown: false,
  },
  {
    id: '2646',
    name: 'Pet maximum weight in lb is',
    displayName: 'Pet maximum weight in lb is',
    isShown: false,
  },
  {
    id: '2808',
    name: 'No pets or service animals allowed',
    displayName: 'No pets or service animals allowed',
    isShown: true,
  },
  {
    id: '2809',
    name: 'Only dogs are allowed',
    displayName: 'Only dogs are allowed',
    isShown: true,
  },
  {
    id: '2810',
    name: 'Only dogs and cats are allowed',
    displayName: 'Only Dogs and cats are allowed',
    isShown: true,
  },
  {
    id: '2811',
    name: 'Maximum number of pets per room',
    displayName: 'Maximum number of pets per room',
    isShown: false,
  },
  {
    id: '5058',
    name: 'Only service animals are allowed',
    displayName: 'Service animals are allowed',
    isShown: true,
  },
  {
    id: '5059',
    name: 'Service animals exempt from fees/restrictions',
    displayName: 'Service animals exempt from fees/restrictions',
    isShown: true,
  },
  { id: '51', name: 'Pets allowed', displayName: 'Pets allowed', isShown: true },
]

// returns the display name for pet attributes
export function getDisplayName(attribute: HotelAttribute) {
  return petAttributes.find((a) => a.id == attribute?.id).displayName
}

// returns whether an attribute is shown or not
export function getIsShown(attribute: HotelAttribute) {
  return petAttributes.find((a) => a.id == attribute?.id).isShown
}

export type AmenityType = { id: string; name: string }

export type GroupedAmenities = {
  category: string
  amenities: AmenityType[]
}

// group all hotel amenities together by category
export function groupAmenities(amenities: AmenityFieldsFragment[]): GroupedAmenities[] {
  const groupedAmenities = []
  Object.keys(uniqueHotelAmenityCategories).forEach((cat) => {
    const allInCat = uniqueHotelAmenityCategories[cat]
    const amsInGroup = amenities.filter((a) => allInCat.includes(a.id.toString()))
    if (amsInGroup.length > 0) {
      groupedAmenities.push({
        category: cat,
        amenities: amsInGroup.map((a) => {
          return { id: a.id, name: a.name }
        }),
      })
    }
  })
  return groupedAmenities.sort((a, b) => (a.amenities.length > b.amenities.length ? -1 : 1))
}

// group all hotel amenities and attributes together by category
export function groupAmenitiesAndAttributes(
  amenities: AmenityFieldsFragment[],
  attributes: HotelPropertyFieldsFragment['attributesGeneral'],
): GroupedAmenities[] {
  const groupedAmenities = []
  Object.keys(uniqueHotelAmenityCategories).forEach((cat) => {
    const allInCat = uniqueHotelAmenityCategories[cat]
    const amsInGroup = amenities.filter((a) => allInCat.includes(a.id.toString()))
    if (amsInGroup.length > 0) {
      groupedAmenities.push({
        category: cat,
        amenities: amsInGroup.map((a) => {
          return { id: a.id, name: a.name }
        }),
      })
    }
  })
  Object.keys(uniqueHotelAttributeCategories).forEach((cat) => {
    const allInCat = uniqueHotelAttributeCategories[cat].map((c) => c.toString())
    const amsInGroup = attributes.filter((a) => allInCat.includes(a.id.toString()))
    if (amsInGroup.length > 0) {
      const group = groupedAmenities.find((ga) => ga.category == cat)
      if (group != null) {
        group.amenities = [
          ...group.amenities,
          ...amsInGroup.map((a) => {
            return { id: a.id, name: a.name }
          }),
        ]
      } else {
        groupedAmenities.push({
          category: cat,
          amenities: amsInGroup.map((a) => {
            return { id: a.id, name: a.name }
          }),
        })
      }
    }
  })
  return groupedAmenities.sort((a, b) => (a.amenities.length > b.amenities.length ? -1 : 1))
}

// group all hotel room amenities together by category
export function groupRoomAmenities(amenities: AmenityFieldsFragment[]): GroupedAmenities[] {
  const groupedAmenities = []
  Object.keys(uniqueRoomAmenityCategories).forEach((cat) => {
    const allInCat = uniqueRoomAmenityCategories[cat]
    const amsInGroup = amenities.filter((a) => allInCat.includes(a.id.toString()))
    if (amsInGroup.length > 0) {
      groupedAmenities.push({
        category: cat,
        amenities: amsInGroup.map((a) => {
          return { id: a.id, name: a.name }
        }),
      })
    }
  })
  return groupedAmenities.sort((a, b) => (a.amenities.length > b.amenities.length ? -1 : 1))
}

// group all hotel room attributes together by category
export function groupRoomAttributes(
  attributes: HotelPropertyFieldsFragment['attributesGeneral'],
): GroupedAmenities[] {
  const groupedAttributes = []
  Object.keys(uniqueHotelAttributeCategories).forEach((cat) => {
    const allInCat = uniqueHotelAttributeCategories[cat].map((c) => c.toString())
    const amsInGroup = attributes.filter((a) => allInCat.includes(a.id.toString()))
    if (amsInGroup.length > 0) {
      groupedAttributes.push({
        category: cat,
        amenities: amsInGroup.map((a) => {
          return { id: a.id, name: a.name }
        }),
      })
    }
  })
  return groupedAttributes.sort((a, b) => (a.amenities.length > b.amenities.length ? -1 : 1))
}

// returns all essential attributes
export function getEssentialHotelAttributes(
  attributes: HotelPropertyFieldsFragment['attributesGeneral'],
): HotelPropertyFieldsFragment['attributesGeneral'] {
  return attributes.filter((a) => essentialHotelAttributes.includes(a.id))
}

// return all covid sttributes
export function getCovidAttributes(
  attributes: HotelPropertyFieldsFragment['attributesGeneral'],
): HotelPropertyFieldsFragment['attributesGeneral'] {
  return attributes.filter((a) => COVIDAttributes.includes(a.id))
}

// returns all accessibility amenities
export function getAccessibilityAmenities(amenities: GroupedAmenities[]): AmenityType[] {
  const category = amenities.find((a) => a.category == 'Accessibility')
  return category != null ? category?.amenities : []
}

// returns all pet amenities
export function getPetAmenities(amenities: GroupedAmenities[]): AmenityType[] {
  const category = amenities.find((a) => a.category == 'Pets')
  return category != null ? category?.amenities : []
}

// returns all child amenities
export function getChildAmenities(amenities: GroupedAmenities[]): AmenityType[] {
  const category = amenities.find((a) => a.category == 'For Children')
  return category != null ? category?.amenities : []
}

// returns all business amenities
export function getBusinessAmenities(amenities: GroupedAmenities[]): AmenityType[] {
  const category = amenities.find((a) => a.category == 'Business')
  return category != null ? category?.amenities : []
}

// returns all dining amenities
export function getDiningAmenities(amenities: GroupedAmenities[]): AmenityType[] {
  const category = amenities.find((a) => a.category == 'Dining')
  return category != null ? category?.amenities : []
}

// returns all amenities excluding accessibility
export function getMainAmenities(amenities: GroupedAmenities[]): GroupedAmenities[] {
  return amenities.filter((g) => !['Accessibility'].includes(g.category)) ?? []
}

export type HotelAmenityHighlights = {
  name: string
  isAlert: boolean
  icon: string
}

// returns array with hotel highlights
export function getHotelHighlights(
  amenities: AmenityFieldsFragment[],
  attributesPets: HotelPropertyFieldsFragment['attributesPets'] = [],
): HotelAmenityHighlights[] {
  const highlights = []
  // 24 hour front desk
  if (amenities.find((a) => a.id == '2063') != null) {
    highlights.push({ name: '24 hour front desk', isAlert: false, icon: '24-hour' })
  }
  // Concierge
  if (amenities.find((a) => a.id == '43' || a.id == '2189' || a.id == '2387') != null) {
    highlights.push({ name: 'Concierge services', isAlert: false, icon: 'concierge' })
  }
  // # of pools
  if (
    amenities.find((a) => uniqueHotelAmenityCategories['Pool'].includes(a.id.toString())) != null
  ) {
    const numPools =
      (amenities?.find((a) => a.id == '2820')?.value != null
        ? Number(amenities?.find((a) => a.id == '2820')?.value)
        : 0) +
      (amenities?.find((a) => a.id == '2821')?.value != null
        ? Number(amenities?.find((a) => a.id == '2821')?.value)
        : 0)
    if (numPools > 0)
      highlights.push({ name: pluralize(numPools, 'pool', 'pools'), isAlert: false, icon: 'pool' })
    else highlights.push({ name: 'Pool', isAlert: false, icon: 'pool' })
  }
  // # of restaurants
  const numRestaurants =
    amenities?.find((a) => a.id == '2537')?.value != null
      ? Number(amenities?.find((a) => a.id == '2537')?.value)
      : 0
  if (numRestaurants > 0)
    highlights.push({
      name: pluralize(numRestaurants, 'restaurant', 'restaurants'),
      isAlert: false,
      icon: 'restaurant',
    })
  else if (amenities?.find((a) => a.id == '19') != null)
    highlights.push({ name: 'Restaurant', isAlert: false, icon: 'restaurant' })

  // Coffee Shop or Cafe
  const numCafe =
    amenities?.find((a) => a.id == '3929')?.value != null
      ? Number(amenities?.find((a) => a.id == '3929')?.value)
      : 0
  if (numCafe > 0)
    highlights.push({
      name: pluralize(numCafe, 'cafe', 'cafes'),
      isAlert: false,
      icon: 'cafe',
    })
  else if (amenities?.find((a) => a.id == '2004') != null)
    highlights.push({ name: 'Cafe', isAlert: false, icon: 'cafe' })

  // # of bars
  const numBars =
    amenities?.find((a) => a.id == '3913')?.value != null
      ? Number(amenities?.find((a) => a.id == '3913')?.value)
      : 0
  if (numBars > 0)
    highlights.push({
      name: pluralize(numBars, 'bar', 'bars'),
      isAlert: false,
      icon: 'bar',
    })
  else if (amenities?.find((a) => a.id == '3') != null)
    highlights.push({ name: 'Bar/lounge', isAlert: false, icon: 'bar' })
  // # of beach bars
  const numBeachBars =
    amenities?.find((a) => a.id == '3915')?.value != null
      ? Number(amenities?.find((a) => a.id == '3915')?.value)
      : 0
  if (numBeachBars > 0)
    highlights.push({
      name: pluralize(numBeachBars, 'beach bar', 'beach bars'),
      isAlert: false,
      icon: 'beach-bar',
    })
  else if (
    amenities?.find((a) => a.id == '3777' || a.id == '1073744528' || a.id == '1073744529') != null
  )
    highlights.push({ name: 'Beach bar or club', isAlert: false, icon: 'beach-bar' })

  // # of poolside bars
  const numPoolBars =
    amenities?.find((a) => a.id == '3914')?.value != null
      ? Number(amenities?.find((a) => a.id == '3914')?.value)
      : 0
  if (numPoolBars > 0)
    highlights.push({
      name: pluralize(numPoolBars, 'poolside bar', 'poolside bars'),
      isAlert: false,
      icon: 'pool-bar',
    })
  else if (amenities?.find((a) => a.id == '400') != null)
    highlights.push({ name: 'Poolside bar', isAlert: false, icon: 'pool-bar' })

  // # of swim-up bars
  const numSwimUpBars =
    amenities?.find((a) => a.id == '3916')?.value != null
      ? Number(amenities?.find((a) => a.id == '3916')?.value)
      : 0
  if (numSwimUpBars > 0)
    highlights.push({
      name: pluralize(numSwimUpBars, 'swim-up bar', 'swim-up bars'),
      isAlert: false,
      icon: 'swim-up-bar',
    })
  else if (amenities?.find((a) => a.id == '2000') != null)
    highlights.push({ name: 'Swim-up bar', isAlert: false, icon: 'swim-up-bar' })

  // Spa (full or partial)
  if (amenities.find((a) => a.id == '2017' || a.id == '2123' || a.id == '2129' || a.id == '2822')) {
    highlights.push({ name: 'Spa services', isAlert: false, icon: 'spa' })
  }
  // Fitness center
  if (
    amenities.find(
      (a) =>
        a.id == '4468' || a.id == '9' || a.id == '1073742765' || a.id == '2008' || a.id == '5054',
    ) != null
  ) {
    highlights.push({ name: 'Fitness facility', isAlert: false, icon: 'fitness' })
  }
  // Laundry service
  if (amenities.find((a) => a.id == '2070' || a.id == '369') != null) {
    highlights.push({ name: 'Laundry facility', isAlert: false, icon: 'laundry' })
  }
  // Golf Course
  if (amenities.find((a) => a.id == '2617') != null) {
    highlights.push({ name: 'Golf corse', isAlert: false, icon: 'golf' })
  }
  // Casino
  if (amenities.find((a) => a.id == '2112') != null) {
    highlights.push({ name: 'Casino', isAlert: false, icon: 'casino' })
  }
  // Vineyard
  if (amenities.find((a) => a.id == '1073744072' || a.id == '4025') != null) {
    highlights.push({ name: 'Winery', isAlert: false, icon: 'vineyard' })
  }
  // Nature Reserve
  if (amenities.find((a) => a.id == '1073744085') != null) {
    highlights.push({ name: 'Nature reserve', isAlert: false, icon: 'nature' })
  }
  // Aquatic Center
  if (amenities.find((a) => '1073744116' == a.id) != null) {
    highlights.push({ name: 'Aquatic center', isAlert: false, icon: 'aquatic' })
  }
  // Marina
  if (amenities.find((a) => '2128' == a.id) != null) {
    highlights.push({ name: 'Marina', isAlert: false, icon: 'marina' })
  }
  // Dock
  if (amenities.find((a) => a.id == '1073744233') != null) {
    highlights.push({ name: 'Dock', isAlert: false, icon: 'dock' })
  }
  // Rooftop terrace
  if (amenities.find((a) => a.id == '3912') != null) {
    highlights.push({ name: 'Rooftop terrace', isAlert: false, icon: 'terrace' })
  }
  // Beach
  if (
    amenities.some((a) => uniqueHotelAmenityCategories['Beach'].includes(a.id.toString())) == true
  ) {
    highlights.push({ name: 'On or near a beach', isAlert: false, icon: 'beach' })
  }
  // Coworking space
  if (amenities.find((a) => a.id == '1073744898') != null) {
    highlights.push({ name: 'Coworking space', isAlert: false, icon: 'coworking' })
  }
  // Nightclub
  if (amenities.find((a) => a.id == '2010') != null) {
    highlights.push({ name: 'Nightclub', isAlert: false, icon: 'nightclub' })
  }
  // Ski Shuttle
  if (amenities.find((a) => a.id == '2136' || a.id == '4421' || a.id == '4422') != null) {
    highlights.push({ name: 'Ski shuttle available', isAlert: false, icon: 'shuttle' })
  }
  // Ski in/out
  if (amenities.find((a) => a.id == '2082') != null) {
    highlights.push({ name: 'Ski-in/ski-out access', isAlert: false, icon: 'ski' })
  }
  // Water park
  if (amenities.find((a) => a.id == '2819' || a.id == '4174' || a.id == '4175') != null) {
    highlights.push({ name: 'Water park', isAlert: false, icon: 'waterpark' })
  }
  // Tennis Court
  if (
    amenities.find(
      (a) => a.id == '2618' || a.id == '3373' || a.id == '3374' || a.id == '3375' || a.id == '3376',
    ) != null
  ) {
    highlights.push({ name: 'Tennis court', isAlert: false, icon: 'tennis' })
  }
  // Hiking / Biking Nearby
  if (amenities.find((a) => a.id == '347') != null) {
    highlights.push({ name: 'Hiking / biking', isAlert: false, icon: 'hiking' })
  }
  // babysitting
  if (amenities.find((a) => a.id == '4695' || a.id == '4696' || a.id == '6') != null) {
    highlights.push({ name: 'Child care', isAlert: false, icon: 'childcare' })
  }
  // Free wifi
  if (
    amenities.find(
      (a) => a.id == '1073743392' || a.id == '1073743393' || a.id == '1073743394' || a.id == '2390',
    ) != null
  ) {
    highlights.push({ name: 'Free wifi', isAlert: false, icon: 'wifi' })
  }
  // Free Airport Transportation
  if (amenities.find((a) => a.id == '10') != null) {
    highlights.push({ name: 'Free airport transportation', isAlert: false, icon: 'transfer' })
  }
  // Free breakfast
  if (amenities.find((a) => a.id == '2001') != null) {
    highlights.push({ name: 'Free breakfast', isAlert: false, icon: 'breakfast' })
  }

  // Car Required / recommended
  // Pets Allowed
  const petsAllowedFlag =
    attributesPets?.find((a) => petsNotAllowed.includes(a.id.toString())) != null
      ? false
      : attributesPets?.find((a) => petsAllowed.includes(a.id.toString())) != null
      ? true
      : false
  if (petsAllowedFlag == true) {
    highlights.push({ name: 'Pets allowed', isAlert: false, icon: 'pets' })
  }
  // No / Limited Hot Water
  // Men / Women / Seniors / Adults only
  // No alcohol
  return highlights
}

export const uniqueHotelAmenityCategories = {
  'Parking & Transportation': [
    '10',
    '1073742906',
    '1073743305',
    '1073743306',
    '1073743315',
    '1073744083',
    '1073744236',
    '1073744272',
    '1073744273',
    '1073744274',
    '1073744275',
    '1073744276',
    '1073744277',
    '1073744278',
    '1073744279',
    '1073744280',
    '1073744281',
    '1073744288',
    '1073744290',
    '1073744293',
    '1073744295',
    '1073744296',
    '1073744299',
    '1073744301',
    '1073744302',
    '1073744314',
    '1073744339',
    '1073744340',
    '1073744341',
    '1073744342',
    '1073744343',
    '1073744344',
    '1073744372',
    '1073744373',
    '1073744381',
    '1073744539',
    '1073744540',
    '1073744541',
    '1073744542',
    '2072',
    '2353',
    '2416',
    '2528',
    '2529',
    '2783',
    '2942',
    '2943',
    '2944',
    '2945',
    '2946',
    '2947',
    '2948',
    '2949',
    '2950',
    '2951',
    '3761',
    '3861',
    '3862',
    '3863',
    '3864',
    '4004',
    '4005',
    '4006',
    '4007',
    '4010',
    '4450',
    '4451',
    '4452',
    '4453',
    '4454',
    '4455',
    '4456',
    '56',
  ],
  Gym: ['1073742765', '2008', '2048', '2178', '4468', '5054', '9'],
  'Spa ': ['1073742766'],
  Spa: ['1073742767', '2017', '2123', '2129', '2134', '2135', '2822', '3601', '371', '40'],
  Pool: [
    '1073742768',
    '1073742769',
    '1073743549',
    '1073744114',
    '1073744115',
    '1073744117',
    '1073744118',
    '1073744119',
    '1073744120',
    '1073744121',
    '1073744122',
    '1073744385',
    '1073744386',
    '1073744790',
    '1073744791',
    '1073744825',
    '1073744969',
    '1073744970',
    '1073745192',
    '14',
    '2000',
    '2074',
    '2138',
    '2352',
    '24',
    '2820',
    '2821',
    '400',
    '4435',
    '4436',
    '4438',
  ],
  Entertainment: [
    '1073742791',
    '1073743304',
    '1073743381',
    '1073743382',
    '1073743383',
    '1073743384',
    '1073743386',
    '1073743387',
    '1073743388',
    '1073743954',
    '1073743955',
    '1073743965',
    '1073744071',
    '1073744116',
    '1073744226',
    '1073744233',
    '1073744371',
    '1073744383',
    '1073744451',
    '2010',
    '2047',
    '2128',
    '2385',
    '2618',
    '2817',
    '2818',
    '2819',
    '3373',
    '3374',
    '3375',
    '3376',
    '372',
    '375',
    '4174',
    '4175',
  ],
  Internet: [
    '1073742907',
    '1073743392',
    '1073743393',
    '1073743394',
    '1073743395',
    '2390',
    '2391',
    '2392',
    '2393',
  ],
  'For Children': [
    '1073743385',
    '1073744387',
    '1073744388',
    '1073745242',
    '2014',
    '2186',
    '38',
    '4695',
    '4696',
    '4697',
    '4698',
    '4699',
    '4700',
    '6',
  ],
  Kitchen: ['1073743404', '1073743405', '1073743877'],
  Concierge: [
    '1073743884',
    '1073745241',
    '2016',
    '2063',
    '2064',
    '2066',
    '2067',
    '2177',
    '2189',
    '2387',
    '2594',
    '4003',
    '41',
    '43',
  ],
  Space: [
    '1073743950',
    '1073743951',
    '1073744234',
    '1073744245',
    '1073744379',
    '2049',
    '317',
    '321',
    '324',
    '385',
    '3912',
    '4514',
    '8',
  ],
  Japanese: ['1073743952', '1073743953', '1073744303', '1073744304', '1073744305'],
  Safety: ['1073743956', '1073744235', '1073744374'],
  Casino: [
    '1073743961',
    '1073743962',
    '1073743963',
    '1073743964',
    '1073743966',
    '1073744094',
    '2112',
  ],
  Golf: [
    '1073744062',
    '1073744063',
    '1073744064',
    '1073744065',
    '1073744375',
    '1073744376',
    '1073744377',
    '2617',
  ],
  Romantic: ['1073744066', '1073744099'],
  Dining: [
    '1073744067',
    '1073744141',
    '1073744462',
    '1073744464',
    '1073744465',
    '1073744466',
    '1073744560',
    '1073744572',
    '1073744113',
    '19',
    '2001',
    '2004',
    '2386',
    '2537',
    '3',
    '3500',
    '3547',
    '361',
    '3913',
    '3914',
    '3915',
    '3916',
    '3929',
    '4111',
  ],
  Shopping: ['1073744069', '1073744070', '44', '45'],
  Wine: ['1073744072', '1073744073', '1073744074', '1073744075', '1073744076', '4025'],
  Nature: ['1073744084', '1073744085', '1073744264', '1073744384', '347', '378'],
  Agriculture: ['1073744086', '1073744087', '1073744088'],
  'Event Space': ['1073744110', '1073744111', '1073744112', '1073744378', '2167'],
  Sustainable: ['1073744255'],
  Bicycle: [
    '1073744416',
    '1073744803',
    '1073744805',
    '1073744807',
    '1073744808',
    '1073744809',
    '1073744810',
    '1073744811',
    '1073744812',
    '1073744814',
    '2090',
    '3778',
    '3801',
  ],
  Accessibility: [
    '1073744430',
    '1073744629',
    '1073744630',
    '1073744631',
    '1073744632',
    '1073744633',
    '1073744634',
    '1073744635',
    '1073744636',
    '1073744637',
    '1073744638',
    '1073744639',
    '1073744640',
    '1073744641',
    '1073744642',
    '1073744643',
    '1073744644',
    '1073744645',
    '1073744646',
    '1073744740',
    '1073744904',
    '1073744905',
    '1073744906',
    '1073744907',
    '1073744908',
    '1073744909',
    '1073744910',
    '1073744911',
    '2043',
    '2419',
    '2420',
    '2421',
    '2422',
    '2423',
    '2424',
    '2425',
  ],
  Beach: [
    '1073744526',
    '1073744527',
    '1073744528',
    '1073744529',
    '2080',
    '3777',
    '4428',
    '4429',
    '4431',
    '4432',
    '4433',
    '4434',
    '4437',
    '4467',
    '5015',
  ],
  Pets: ['1073744795', '1073744796', '1073744797', '1073744798', '1073744799'],
  Business: [
    '1073744898',
    '200',
    '2065',
    '2131',
    '2538',
    '2539',
    '2592',
    '2593',
    '3372',
    '3637',
    '3865',
    '81',
  ],
  Laundry: ['2070', '369'],
  Ski: ['2082', '2136', '354', '4421', '4422', '4423'],
  Smoking: ['2137', '2349', '3269'],
}

export const uniqueRoomAmenityCategories = {
  'Climate Control': [
    '1',
    '1073742764',
    '1073743284',
    '1073743285',
    '1073743293',
    '1073743880',
    '2030',
    '331',
  ],
  Accessibility: [
    '1073742599',
    '1073743560',
    '1073744647',
    '1073744649',
    '1073744651',
    '1073744653',
    '1073744655',
    '1073744657',
    '1073744658',
    '1073744660',
    '1073744661',
    '1073744663',
    '1073744665',
    '1073744667',
    '1073744669',
    '1073744671',
    '1073744673',
    '1073744675',
    '1073744677',
    '1073744679',
    '1073744681',
    '1073744683',
    '1073744684',
    '1073744685',
    '1073744686',
    '1073744687',
    '1073744688',
    '1073744689',
    '1073744690',
    '1073744691',
    '121',
  ],
  Business: [
    '1073742600',
    '1073744237',
    '1073745165',
    '1073745166',
    '1073745172',
    '1073745173',
    '2026',
    '3856',
    '5040',
    '5045',
  ],
  Concierge: ['1073742601'],
  Spa: ['1073742602', '2160'],
  Space: [
    '1073742603',
    '1073742604',
    '1073744242',
    '1073744431',
    '1073744441',
    '1073744573',
    '1073744574',
    '1073744706',
    '1073744900',
    '1073744912',
    '1073745167',
    '147',
    '2032',
    '2037',
    '2038',
    '2148',
    '2370',
    '2567',
    '2568',
    '2789',
    '2790',
    '318',
    '322',
    '3267',
    '3779',
    '3857',
    '388',
    '4118',
    '4296',
    '4297',
    '4298',
  ],
  Housekeeping: [
    '1073742676',
    '1073742770',
    '1073743406',
    '1073743926',
    '133',
    '2056',
    '3616',
    '3984',
    '3985',
    '4008',
  ],
  Kitchen: [
    '1073742771',
    '1073742772',
    '1073742773',
    '1073742774',
    '1073743289',
    '1073743291',
    '1073743316',
    '1073743373',
    '1073743948',
    '1073744185',
    '1073744186',
    '1073744187',
    '1073744188',
    '1073744189',
    '1073744190',
    '1073744191',
    '1073744239',
    '1073744243',
    '1073744244',
    '1073744380',
    '1073744435',
    '1073744437',
    '1073744439',
    '1073744443',
    '1073744447',
    '1073744823',
    '1073745170',
    '130',
    '131',
    '132',
    '2057',
    '2058',
    '2059',
    '2130',
    '2158',
    '2181',
    '2378',
    '2379',
    '2381',
    '312',
    '399',
    '5093',
    '6142',
    '6143',
  ],
  Safety: [
    '1073742775',
    '1073744215',
    '1073744694',
    '1073744696',
    '1073744697',
    '1073744698',
    '1073744699',
    '1073744700',
    '1073745168',
    '1073745169',
    '135',
    '146',
    '2389',
  ],
  Entertainment: [
    '1073742776',
    '1073743569',
    '1073743571',
    '1073744223',
    '1073744225',
    '1073744227',
    '1073744228',
    '1073744229',
    '1073744433',
    '1073744445',
    '1073744893',
    '128',
    '2028',
    '2031',
    '2039',
    '2081',
    '2086',
    '2396',
    '2397',
    '2398',
    '2399',
    '2402',
    '2563',
    '2575',
    '2576',
    '26',
    '2806',
    '2807',
    '5105',
    '5106',
    '6147',
  ],
  Bedroom: [
    '1073742815',
    '1073742816',
    '1073743371',
    '1073743918',
    '1073743929',
    '1073743931',
    '1073744284',
    '1073744337',
    '1073745067',
    '2034',
    '2044',
    '2150',
    '2559',
    '2561',
    '2565',
    '2569',
    '2571',
    '3911',
    '4642',
    '4954',
    '5178',
  ],
  Bathroom: [
    '1073742865',
    '1073743370',
    '1073743882',
    '1073743928',
    '1073743957',
    '1073743958',
    '1073744201',
    '1073744202',
    '1073744203',
    '1073744241',
    '1073744764',
    '1073744765',
    '1073744784',
    '1073744785',
    '1073744786',
    '1073744828',
    '1073744831',
    '141',
    '142',
    '143',
    '144',
    '148',
    '2035',
    '2036',
    '2149',
    '2166',
    '2168',
    '2170',
    '2180',
    '2183',
    '2371',
    '2854',
    '3501',
    '3502',
    '3726',
    '3858',
    '5013',
  ],
  'Internet & Phone': [
    '1073742908',
    '1073743396',
    '1073743397',
    '1073743398',
    '1073743399',
    '1073744315',
    '1073744319',
    '1073744321',
    '1073744323',
    '1073744325',
    '1073744327',
    '136',
    '139',
    '2403',
    '2404',
    '2405',
    '2406',
    '2407',
    '2408',
    '3923',
    '3924',
  ],
  Laundry: ['1073743311', '1073743312', '1073744218', '1073744558', '145', '2055', '340'],
  'For Children': [
    '1073743372',
    '1073744219',
    '1073744220',
    '1073744221',
    '1073744222',
    '1073744224',
    '1073744282',
    '1073744283',
    '1073745174',
    '1073745191',
    '2045',
    '2151',
    '4643',
    '4945',
    '4950',
    '4951',
  ],
  Dining: ['1073743409', '1073744382', '1073745171', '20', '2015', '2053'],
  Luxury: ['1073744077', '1073744213', '2025', '2572', '2573', '2577', '3859', '4947'],
  Cleaning: ['1073744192', '1073744240', '1073744622'],
  Other: [
    '1073744214',
    '1073744216',
    '1073744217',
    '2054',
    '2162',
    '2837',
    '3982',
    '4293',
    '4420',
    '5118',
  ],
  Japanese: [
    '1073744306',
    '1073744307',
    '1073744308',
    '1073744309',
    '1073744329',
    '1073744330',
    '1073744331',
    '1073744332',
  ],
  Gym: [
    '1073744708',
    '1073744709',
    '1073744710',
    '1073744711',
    '1073744712',
    '1073744713',
    '1073744714',
    '1073744715',
    '1073744716',
    '1073744717',
    '1073744718',
    '1073744719',
    '1073745143',
  ],
  Location: ['1073744800', '1073744801', '1073744802'],
  Pool: ['2859', '2860'],
  Pets: ['4090'],
  Smoking: ['6176', '6212', '6213'],
}

export const uniqueHotelAttributeCategories = {
  General: [
    '57',
    '58',
    '2003',
    '2791',
    '2940',
    '3357',
    '5052',
    '1073744418',
    '1073744420',
    '1073745021',
    '1073745102',
    '1073745142',
  ],
  Bedroom: ['2544'],
  'For Children': ['2545'],
  Space: ['2549', '3266'],
  'Parking & Transportation': ['2554', '1073743368', '1073743369', '1073744417'],
  Concierge: ['2557', '1073745055', '1073745115'],
  Dining: ['4638', '4639'],
  Bathroom: ['1073743878', '1073743879'],
  Events: ['1073743916', '1073743917'],
  Sustainable: ['1073744252', '1073744253', '1073744254', '1073744256'],
  Safety: ['1073744419'],
}

export const COVIDAttributes = [
  '1073744973',
  '1073744974',
  '1073744990',
  '1073744992',
  '1073744994',
  '1073744996',
  '1073744997',
  '1073744998',
  '1073745000',
  '1073745002',
  '1073745004',
  '1073745006',
  '1073745008',
  '1073745010',
  '1073745012',
  '1073745013',
  '1073745015',
  '1073745016',
  '1073745017',
  '1073745018',
  '1073745019',
  '1073745045',
  '1073745047',
  '1073745049',
  '1073745051',
  '1073745053',
  '1073745057',
  '1073745059',
  '1073745060',
  '1073745061',
  '1073745062',
  '1073745063',
  '1073745064',
  '1073745065',
  '1073745103',
  '1073745105',
  '1073745107',
  '1073745109',
  '1073745113',
  '1073745119',
  '1073745120',
  '1073745121',
  '1073745122',
  '1073745123',
  '1073745124',
  '1073745126',
  '1073745161',
  '1073745162',
  '1073745163',
  '1073745164',
  '1073745200',
  '1073745201',
  '1073745202',
  '1073745203',
  '1073745208',
  '1073745209',
  '1073745210',
  '1073745211',
  '1073745212',
  '1073745213',
  '1073745218',
  '1073745219',
  '1073745220',
  '1073745222',
  '1073745223',
  '1073745224',
  '1073745226',
  '1073745227',
  '1073745228',
  '1073745229',
  '1073745230',
]

export const essentialHotelAttributes = [
  '57',
  '58',
  '2003',
  '2557',
  '2791',
  '2940',
  '3357',
  '4639',
  '1073743368',
  '1073743369',
  '1073743878',
  '1073743879',
  '1073743917',
  '1073744420',
  '1073745209',
  '1073745210',
  '1073745212',
  '1073745218',
  '1073745219',
  '1073745220',
  '1073745222',
  '1073745223',
  '1073745227',
]
