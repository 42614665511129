// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file booking/v1/hotel_reservation.proto (package booking.v1, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage} from "@bufbuild/protobuf";
import {Message, proto3} from "@bufbuild/protobuf";
import {Date} from "../../google/type/date_pb.js";
import {Rate, RateOption} from "../../rates/v1/rate_pb.js";
import {Currency} from "../../common/v1/currency_pb.js";
import {Availability} from "../../rates/v1/price_check_pb.js";

/**
 * @generated from enum booking.v1.TestScenario
 */
export enum TestScenario {
  /**
   * @generated from enum value: TEST_SCENARIO_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TEST_SCENARIO_SUCCESS = 1;
   */
  SUCCESS = 1,

  /**
   * @generated from enum value: TEST_SCENARIO_SOLD_OUT = 2;
   */
  SOLD_OUT = 2,

  /**
   * @generated from enum value: TEST_SCENARIO_PRICE_CHANGED = 3;
   */
  PRICE_CHANGED = 3,

  /**
   * @generated from enum value: TEST_SCENARIO_SERVICE_UNAVAILABLE = 4;
   */
  SERVICE_UNAVAILABLE = 4,

  /**
   * @generated from enum value: TEST_SCENARIO_UNKNOWN_INTERNAL_ERROR = 5;
   */
  UNKNOWN_INTERNAL_ERROR = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(TestScenario)
proto3.util.setEnumType(TestScenario, "booking.v1.TestScenario", [
  { no: 0, name: "TEST_SCENARIO_UNSPECIFIED" },
  { no: 1, name: "TEST_SCENARIO_SUCCESS" },
  { no: 2, name: "TEST_SCENARIO_SOLD_OUT" },
  { no: 3, name: "TEST_SCENARIO_PRICE_CHANGED" },
  { no: 4, name: "TEST_SCENARIO_SERVICE_UNAVAILABLE" },
  { no: 5, name: "TEST_SCENARIO_UNKNOWN_INTERNAL_ERROR" },
]);

/**
 * @generated from message booking.v1.InsertHotelReservationRequest
 */
export class InsertHotelReservationRequest extends Message<InsertHotelReservationRequest> {
  /**
   * @generated from field: string price_check_token = 1;
   */
  priceCheckToken = "";

  /**
   * @generated from field: int32 adult_count = 2;
   */
  adultCount = 0;

  /**
   * @generated from field: repeated int32 child_ages = 3;
   */
  childAges: number[] = [];

  /**
   * @generated from field: string hotel_id = 4;
   */
  hotelId = "";

  /**
   * @generated from field: string room_id = 5;
   */
  roomId = "";

  /**
   * @generated from field: int32 rate_id = 6;
   */
  rateId = 0;

  /**
   * @generated from field: int32 bed_group_id = 7;
   */
  bedGroupId = 0;

  /**
   * @generated from field: google.type.Date check_in = 8;
   */
  checkIn?: Date;

  /**
   * @generated from field: google.type.Date check_out = 9;
   */
  checkOut?: Date;

  /**
   * @generated from field: rates.v1.RateOption rate_option = 11;
   */
  rateOption = RateOption.UNSPECIFIED;

  /**
   * @generated from field: common.v1.Currency currency = 12;
   */
  currency = Currency.UNSPECIFIED;

  /**
   * @generated from field: booking.v1.TestScenario test = 10;
   */
  test = TestScenario.UNSPECIFIED;

  constructor(data?: PartialMessage<InsertHotelReservationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "booking.v1.InsertHotelReservationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "price_check_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "adult_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "child_ages", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 4, name: "hotel_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "rate_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "bed_group_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "check_in", kind: "message", T: Date },
    { no: 9, name: "check_out", kind: "message", T: Date },
    { no: 11, name: "rate_option", kind: "enum", T: proto3.getEnumType(RateOption) },
    { no: 12, name: "currency", kind: "enum", T: proto3.getEnumType(Currency) },
    { no: 10, name: "test", kind: "enum", T: proto3.getEnumType(TestScenario) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InsertHotelReservationRequest {
    return new InsertHotelReservationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InsertHotelReservationRequest {
    return new InsertHotelReservationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InsertHotelReservationRequest {
    return new InsertHotelReservationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: InsertHotelReservationRequest | PlainMessage<InsertHotelReservationRequest> | undefined, b: InsertHotelReservationRequest | PlainMessage<InsertHotelReservationRequest> | undefined): boolean {
    return proto3.util.equals(InsertHotelReservationRequest, a, b);
  }
}

/**
 * @generated from message booking.v1.InsertHotelReservationResponse
 */
export class InsertHotelReservationResponse extends Message<InsertHotelReservationResponse> {
  /**
   * @generated from oneof booking.v1.InsertHotelReservationResponse.reservation
   */
  reservation: {
    /**
     * @generated from field: string trip_id = 1;
     */
    value: string;
    case: "tripId";
  } | {
    /**
     * @generated from field: string ephemeral_reservation_id = 2;
     */
    value: string;
    case: "ephemeralReservationId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: rates.v1.Availability status = 3;
   */
  status = Availability.UNSPECIFIED;

  /**
   * @generated from field: optional rates.v1.Rate new_rate = 4;
   */
  newRate?: Rate;

  constructor(data?: PartialMessage<InsertHotelReservationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "booking.v1.InsertHotelReservationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "trip_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "reservation" },
    { no: 2, name: "ephemeral_reservation_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "reservation" },
    { no: 3, name: "status", kind: "enum", T: proto3.getEnumType(Availability) },
    { no: 4, name: "new_rate", kind: "message", T: Rate, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InsertHotelReservationResponse {
    return new InsertHotelReservationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InsertHotelReservationResponse {
    return new InsertHotelReservationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InsertHotelReservationResponse {
    return new InsertHotelReservationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InsertHotelReservationResponse | PlainMessage<InsertHotelReservationResponse> | undefined, b: InsertHotelReservationResponse | PlainMessage<InsertHotelReservationResponse> | undefined): boolean {
    return proto3.util.equals(InsertHotelReservationResponse, a, b);
  }
}

