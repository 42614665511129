<script lang="ts">
  import { browser } from '$app/environment'
  import type { PriceLineItem } from '$lib/gen/rates/v1/rate_pb'
  import modal from '$lib/stores/modal-state'
  import { formatDateRangeShort } from 'src/utils/calendar'
  import type { MaxOccupancy } from 'src/utils/stays/occupancy'
  import { pluralize } from 'src/utils/text-helpers'
  import { createEventDispatcher } from 'svelte'

  export let checkIn: Date
  export let checkOut: Date
  export let adults: number
  export let children: number[]
  export let blockPopUp = false
  export let loading = false
  export let maxOccupancy: MaxOccupancy = null
  export let numSelectedRooms = 0
  export let selectedRoomName: string = null
  export let totalRooms = 0
  export let quotePriceItems: PriceLineItem[] = []
  export let totalAmount: number = null
  export let totalPropertyFees: number = null
  export let hideOnLarge = true

  $: guestCount = adults + children?.length ?? 0

  const dispatch = createEventDispatcher()

  function showCalendarPanel() {
    dispatch('popUp')
    modal.open('calendar-hotel-page')
  }

  function showGuestSelectPanel() {
    dispatch('popUp')
    modal.open('guest-select-hotel-page', { maxOccupancy })
  }

  function showPriceBreakdown() {
    modal.open('price-breakdown', {
      quotePriceItems,
      totalAmount,
      totalPropertyFees,
    })
  }
</script>

<div
  class={`h-24 z-[11] w-full px-4 py-2 text-black bg-white sm:h-28 border-grey-dk border-t fixed left-0 bottom-0 ${
    hideOnLarge ? 'lg:hidden' : ''
  }`}
>
  <div class="flex items-center justify-between my-1 sm:my-2">
    <div class="flex flex-col items-start justify-between flex-1 pr-4">
      <button
        disabled={loading || numSelectedRooms == 0 || quotePriceItems?.length == 0}
        on:click={showPriceBreakdown}
        class={`flex items-center text-start flex-wrap w-full leading-5 sm:text-lg gap-x-2 justify-start bg-transparent`}
      >
        <slot name="price" />
      </button>
      <span
        class="flex items-center text-start flex-1 flex-wrap text-xs text-gray-800 sm:text-sm gap-x-1"
      >
        <button
          class={`text-start ${blockPopUp ? 'cursor-default' : 'cursor-pointer underline'} ${
            !browser ? 'blur-sm' : ''
          }`}
          on:click={blockPopUp ? null : showCalendarPanel}
          >{formatDateRangeShort(checkIn, checkOut)}</button
        >
        <span class="block text-gray-400">•</span>
        <button
          class={`text-start ${blockPopUp ? 'cursor-default' : 'cursor-pointer underline'} ${
            !browser ? 'blur-sm' : ''
          }`}
          on:click={blockPopUp ? null : showGuestSelectPanel}
          >{pluralize(guestCount, 'guest', 'guests')}</button
        >
      </span>
    </div>
    <slot name="button" />
  </div>
  <div class="flex pt-1">
    <div class="text-xs text-gray-800 sm:text-sm tracking-tight truncate">
      {#if !browser || loading}
        Checking rooms and rates...
      {:else if numSelectedRooms > 1}
        {numSelectedRooms} rooms selected
      {:else if numSelectedRooms == 1 && selectedRoomName != undefined}
        {selectedRoomName}
      {:else if numSelectedRooms == 0 && totalRooms > 0}
        Select from {pluralize(totalRooms, 'room', 'rooms')}
      {:else}
        No rooms available
      {/if}
    </div>
    <div class="flex-shrink-0" />
  </div>
</div>
