import type { Day } from '$lib/gen/rates/v1/calendar_pb'
import type { LegacyRoomSelection } from '$lib/utils/pricing/room-selection-helper'
import { writable } from 'svelte/store'

type PricePreviewStore = {
  selectedRooms: LegacyRoomSelection[]
  totalAmount: number
  totalRooms: number
  isFetching: boolean
  priceCalendar: Day[]
  hasSelectedDates: boolean
  isFetchingRatesCalendar: boolean
  currency?: string
}

const defaults = {
  selectedRooms: [],
  totalAmount: 0,
  totalRooms: 0,
  isFetching: false,
  priceCalendar: [],
  hasSelectedDates: false,
  isFetchingRatesCalendar: false,
  currency: 'USD',
}

const pricePreview = writable<PricePreviewStore>(defaults)

export default {
  subscribe: pricePreview.subscribe,
  set: pricePreview.set,
  update: pricePreview.update,
  reset() {
    pricePreview.set(defaults)
  },
}
